import { ref } from 'vue';
import { defineStore } from 'pinia';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';

export const useGlobalStore = defineStore('globalstore', () => {
  const authClient = ref<ClientAuthenticatedAdalApiClient | null>(null);

  function init(client: ClientAuthenticatedAdalApiClient) {
    authClient.value = client;
  }

  return {
    authClient,
    init,
  };
});
