<template>
  <core-view-template title="Item Update">
    <v-container>
      <v-row v-if="!created && !createdMulti">
        <edit-item-update
          :IsNew="true"
          @request-data-multi="requestDataMulti"
          :Processing.sync="processing"
        />
      </v-row>
      <v-row v-if="created">
        <v-btn @click="newItemClick">Item Update</v-btn>
      </v-row>
      <v-row v-if="createdMulti"> Item update created.</v-row>
      <v-row v-if="createdMulti">
        <router-link :to="viewItemsLink">View Requests</router-link>
      </v-row>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { useVpNewItemStore } from '@/stores/vpnewitemstore';
import EditItemUpdate from '@/components/vendorportal/EditItemUpdate.vue';
import { convertBatchType } from '@/utils/FableUtils.fs.js';
import { initBatch } from '@/utils/DomainExports.fs.js';
import { BatchType } from '@/utils/VendorDomainTypes';
const vpNewItemStore = useVpNewItemStore();
const { createItemUpdate, createBatch } = vpNewItemStore;
const emit = defineEmits(['snackbar-error']);
const created = ref(false);
const createdMulti = ref(false);
const processing = ref(false);
const viewItemsLink = {
  name: 'vendorportal_batchsearch',
};
function newItemClick() {
  created.value = false;
  createdMulti.value = false;
}
const requestData = async (data: any, isLast: boolean) => {
  const request = {
    AggregateId: data.id,
    RequestID: 0,
    PrivateLabel: data.privateLabel,
    RequestType: data.requestType,
    Status: data.status,
    FullUnitBarcode: data.fullUnitBarcode,
    CaseBarcode: data.caseBarcode,
    OtherBarcode: data.otherBarcode,
    ProductName: data.productName,
    RetailPackageSize: data.retailPackageSize,
    RetailPackageUOM: data.retailPackageUOM,
    ManufacturerAccountNumber: data.manufacturerAccountNumber,
    Brand: data.brand,
    VendorId: data.vendorId,
    ExternalItemNumber: data.externalItemNumber,
    MasterCase: data.masterCase,
    InnerCasePack: data.innerCasePack,
    LayerQuantity: data.layerQuantity,
    PalletQuantity: data.palletQuantity,
    DistributionNetwork: data.distributionNetwork,
    DirectToStore: data.directToStore,
    DirectToStoreOrVendorIntoPspdPurchaseCost: data.directToStoreOrVendorIntoPspdPurchaseCost,
    MfgSuggestedRetail: data.mfgSuggestedRetail,
    MapPrice: data.mapPrice,
    IMap: data.imap,
    AutoShipEligible: data.autoShipEligible,
    InitialDiscountFundingPercent: data.initialDiscountFundingPercent,
    OnGoingFundingDiscountPercent: data.onGoingFundingDiscountPercent,
    MaterialSafetyDataSheetNeeded: data.materialSafetyDataSheetNeeded,
    MinimumOrderQuantity: data.minimumOrderQuantity,
    UnitWeight: data.unitWeight,
    UnitWeightUOM: data.unitWeightUOM,
    UnitHeight: data.unitHeight,
    UnitWidth: data.unitWidth,
    UnitDepth: data.unitDepth,
    CaseHeight: data.caseHeight,
    CaseWidth: data.caseWidth,
    CaseDepth: data.caseDepth,
    ProductAttribute: data.productAttribute,
    Images: data.images,
    ProgramName: data.programName,
    HtsCode: data.htsCode,
    DutyRate: data.dutyRate,
    FobPort: data.fobPort,
    PluScan: data.pluScan,
    PluAssigned: data.pluAssigned,
    DaxItemNumber: data.daxItemNumber,
    SignsAndLabelsDescription1: data.signsAndLabelsDescription1,
    SignsAndLabelsDescription2: data.signsAndLabelsDescription2,
    VendorNumber: data.vendorNumber,
    ShipsToStores: data.shipsToStores,
    ShipUnitCostFromPspd: data.shipUnitCostFromPspd,
    RetailBasePrice: data.retailBasePrice,
    RetailHighPrice: data.retailHighPrice,
    Hierarchy: data.hierarchy,
    CategoryManagerName: data.categoryManagerName,
    PspdInventoryManagerName: data.pspdInventoryManagerName,
    Reason: data.reason,
    Distributors: data.dsdDistributor,
    IsMulti: true,
    IsLast: isLast,
    BatchId: data.batchId,
    NewUPC: data.newFullUnitBarcode,
    AnticipatedDateOfItemChange: data.dateOfChange,
    Season: data.season,
    Seasonal: data.seasonal,
    Year: data.year,
    Category: data.category,
    PlanogramLocationGroup: data.planogramLocationGroup,
  };
  processing.value = true;
  const response = await createItemUpdate(request);
  if (response) {
    if (isLast) {
      createdMulti.value = true;
    }
    return response;
  }
};
const requestDataMulti = async (data: any[]) => {
  try {
    const batch = initBatch();
    for (const [index, item] of data.entries()) {
      item.batchId = batch.BatchId;
      await requestData(item, index === data.length - 1);
    }
    batch.BatchType = convertBatchType(BatchType.PackageChangeBatch);
    batch.CategoryManagerName = data[0].categoryManagerName;
    await createBatch(batch);
  } catch (err) {
    console.error(err);
    emit('snackbar-error', {
      text: 'Error creating item update',
      err,
      id: '4ca2773b-648f-4031-b1a1-a19282eea7e2',
    });
  } finally {
    processing.value = false;
  }
};
</script>
