import * as dto from '@psp/pogohdto';
import { JournalType } from '@/stores/handheldstore';

export type PogohJournalType =
  | dto.pogohMobileJournal
  | dto.pogohAdjustmentMobileJournal
  | dto.pogohMovementMobileJournal
  | dto.pogohPurchaseOrderMobileJournal
  | dto.pogohQuickCountMobileJournal
  | dto.pogohReturnOrderMobileJournal
  | dto.pogohSignsAndLabelsMobileJournal
  | dto.pogohTransferOrderMobileJournal;

export interface JournalOptions {
  journalTypeId: JournalType;
  journalDtoEndpoint: string;
  gridHeaders: any[];
  lineHeaders: any[];
}
export class AdjustJournalOptions implements JournalOptions {
  journalTypeId = JournalType.Adjustment;
  journalDtoEndpoint = 'AdjustmentMobileJournal';
  gridHeaders: any[] = [
    { text: 'Name', value: 'JournalNameId', sortable: true },
    { text: 'Description', value: 'JournalDescription', sortable: true },
  ];
  lineHeaders = [];
}
export class LabelsAndSignsOptions implements JournalOptions {
  journalTypeId = JournalType.LabelsAndSigns;
  journalDtoEndpoint = 'SignsAndLabelsMobileJournal';
  gridHeaders: any[] = [
    { text: 'Label Type', value: 'LabelType', sortable: true },
    { text: 'Description', value: 'HeaderDescription', sortable: true },
  ];
  lineHeaders = [];
}
export class LiveQuickCountOptions implements JournalOptions {
  journalTypeId = JournalType.LiveQuickCount;
  journalDtoEndpoint = 'QuickCountMobileJournal';
  gridHeaders: any[] = [{ text: 'Name', value: 'JournalNameId', sortable: true }];
  lineHeaders: any[] = [{ text: 'On Hand', value: 'OnHand', sortable: true, align: 'center' }];
}
export class MovementOptions implements JournalOptions {
  journalTypeId = JournalType.Movement;
  journalDtoEndpoint = 'MovementMobileJournal';
  gridHeaders: any[] = [{ text: 'Name', value: 'JournalNameId', sortable: true }];
  lineHeaders = [];
}
export class PurchaseOrderOptions implements JournalOptions {
  journalTypeId = JournalType.PurchaseOrder;
  journalDtoEndpoint = 'PurchaseOrderMobileJournal';
  gridHeaders: any[] = [
    { text: 'Vend Account', value: 'VendAccount', sortable: true },
    { text: 'Vend Name', value: 'VendName', sortable: true },
  ];
  lineHeaders = [];
}
export class QuickCountOptions implements JournalOptions {
  journalTypeId = JournalType.QuickCount;
  journalDtoEndpoint = 'QuickCountMobileJournal';
  gridHeaders: any[] = [{ text: 'Name', value: 'JournalNameId', sortable: true }];
  lineHeaders: any[] = [{ text: 'On Hand', value: 'OnHand', sortable: true, align: 'center' }];
}
export class ReturnOrderOptions implements JournalOptions {
  journalTypeId = JournalType.ReturnOrder;
  journalDtoEndpoint = 'ReturnOrderMobileJournal';
  gridHeaders: any[] = [
    { text: 'RMA', value: 'Rma', sortable: true },
    { text: 'Vend Account', value: 'VendAccount', sortable: true },
    { text: 'Vend Name', value: 'VendName', sortable: true },
  ];
  lineHeaders = [];
}
export class TransferOrderOptions implements JournalOptions {
  journalTypeId = JournalType.TransferOrder;
  journalDtoEndpoint = 'TransferOrderMobileJournal';
  gridHeaders: any[] = [
    { text: 'From Location', value: 'FromWmslocationId', sortable: true },
    { text: 'To Location', value: 'ToWmslocationId', sortable: true },
  ];
  lineHeaders = [];
}

export function journalOptionsFactory(journalTypeId: JournalType): JournalOptions {
  switch (journalTypeId) {
    case JournalType.Adjustment:
      return new AdjustJournalOptions();
    case JournalType.LabelsAndSigns:
      return new LabelsAndSignsOptions();
    case JournalType.LiveQuickCount:
      return new LiveQuickCountOptions();
    case JournalType.Movement:
      return new MovementOptions();
    case JournalType.PurchaseOrder:
      return new PurchaseOrderOptions();
    case JournalType.QuickCount:
      return new QuickCountOptions();
    case JournalType.ReturnOrder:
      return new ReturnOrderOptions();
    case JournalType.TransferOrder:
      return new TransferOrderOptions();
    default:
      throw new Error('Invalid journal type');
  }
}
