<template>
  <core-view-template title="OS & D Home">
    <v-row>
      <v-col>
        <v-text-field
          v-model="osdHomeSearch"
          label="Search (PO, ASN, vendor #, vendor name, item #, item desc)"
          clearable
          @click.stop
          @click:clear.stop="getData"
          v-debounce:300="getData"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedEntryStatuses"
          label="Entry Statuses"
          :items="entryStatuses"
          @change="getData"
          item-text="Description"
          item-value="EntryStatusId"
          chips
          multiple
          clearable
          deletable-chips
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedEntryReasons"
          label="Entry Reasons"
          :items="entryReasons"
          @change="getData"
          item-text="Description"
          item-value="EntryReasonId"
          chips
          multiple
          clearable
          deletable-chips
        >
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedDistributionCenters"
          label="Distribution Centers"
          :items="distributionCenters"
          @change="getData"
          item-text="Description"
          item-value="DistributionCenterId"
          chips
          multiple
          clearable
          deletable-chips
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="headers">
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="headers"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="gettingHeaders"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          class="elevation-1 scrollable"
        >
          <template v-slot:item.Header.PoArrivalDate="{ item }">
            {{ formatters.formatDate(item.Header.PoArrivalDate) }}
          </template>
          <template v-slot:item.Header.UsernameCreatedBy="{ item }">
            {{ item.Header.CreatedByName }} | {{ item.Header.UsernameCreatedBy }}
          </template>
          <template v-slot:item.Header.CreatedDate="{ item }">
            {{ formatters.formatDatetime(item.Header.CreatedDate, true) }}
          </template>
          <template v-slot:item.Header.EntryStatusId="{ item }">
            {{ getStatus(item.Header.EntryStatusId) }}
          </template>
          <template v-slot:item.details="{ item }">
            <router-link
              :to="{
                name: 'osd_entry_detail',
                params: { entryHeaderId: item.Header.EntryHeaderId.toString() },
              }"
            >
              Details
            </router-link>
          </template>

          <template v-slot:body.append="{}">
            <tr>
              <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId">
                </infinite-loading>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </core-view-template>
</template>

<script setup lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useOsdStore, OsdSearchParams, OsdConsts } from '@/stores/osdstore';
import { storeToRefs } from 'pinia';
import { useFormatters } from '@/composables/formatters';

defineComponent({
  name: 'OsdHome',
});

const store = useOsdStore();

const {
  headers,
  gettingHeaders,
  skipheaders,
  lastHeaderInfoCount,
  selectedEntryStatuses,
  entryStatuses,
  entryReasons,
  selectedEntryReasons,
  distributionCenters,
  selectedDistributionCenters,
  osdHomeSearch,
} = storeToRefs(store);
const formatters = useFormatters();

const tableHeaders = [
  { text: 'ID', value: 'Header.EntryHeaderId' },
  { text: 'PO #', value: 'Header.Ponumber' },
  { text: 'Description', value: 'Header.Description' },
  { text: 'PO Arrival', value: 'Header.PoArrivalDate' },
  { text: 'Created By', value: 'Header.UsernameCreatedBy' },
  { text: 'Created On', value: 'Header.CreatedDate' },
  { text: 'Status', value: 'Header.EntryStatusId' },
  { text: '# of Lines', value: 'LineCount' },
  { text: 'Details', value: 'details', sortable: false },
];
const infiniteId = +new Date();

onMounted(async () => {
  // get current search params if saved
  const osdParamsLs = localStorage.getItem(OsdConsts.SearchLocalStorageKey);
  if (osdParamsLs && osdParamsLs.length) {
    try {
      const osdParams = JSON.parse(osdParamsLs) as OsdSearchParams;
      selectedEntryStatuses.value = osdParams.entryStatuses;
      selectedEntryReasons.value = osdParams.entryReasons;
      selectedDistributionCenters.value = osdParams.distributionCenters;
    } catch (err) {
      console.error(err);
    }
  }

  await Promise.all([
    store.getEntryStatuses(),
    store.getEntryReasons(),
    store.getDistributionCenters(),
  ]);

  clearHeaders();
});

function clearHeaders() {
  skipheaders.value = 0;
  headers.value = [];
}

async function getData() {
  clearHeaders();
  await store.getEntryHeaders();
}

function infiniteHandler($state: any) {
  store
    .getEntryHeaders()
    .then(() => {
      if (lastHeaderInfoCount.value > 0) {
        $state.loaded();
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}

function getStatus(statusId: number) {
  const status = entryStatuses.value?.filter(x => x.EntryStatusId === statusId);
  if (status && status.length === 1) {
    return status[0].Description;
  }
  return '';
}
</script>
