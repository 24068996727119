<template>
  <core-view-template :title="title">
    <div>
      <show-impersonation />
      <v-row>
        <v-col cols="2">
          <ExcelExport
            :buttonText="'Export'"
            :loading="loading"
            :protectFile="protectExport"
            @click="exportToExcel"
            @update:protectFile="val => (protectExport = val)"
          />
        </v-col>
      </v-row>
      <h3>Transactions</h3>
      <CustTransGrid
        :headers="transHeaders"
        :custTrans="transItems"
        :loading="loading"
        :hideDefaultFooter="true"
        :page="1"
        :isServerSide="false"
        :items-per-page="-1"
      />
    </div>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import CustTransGrid from '@/components/vendorportal/CustTransGrid.vue';
import { useRoute } from 'vue-router/composables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import { useTransGridShared } from '@/composables/TransGridShared';
import ExcelExport from '@/components/vendorportal/ExcelExport.vue';
import { getRouteValue } from '@/utils/Utils';
defineComponent({
  name: 'CustTransPayRefDetail',
});
const gridShared = useTransGridShared();
const transHeaders = [
  gridShared.dataAreaIdHeader,
  gridShared.custAcctHeader,
  gridShared.paymReferenceHeader,
  gridShared.paymModeHeader,
  gridShared.sortable(gridShared.invoiceHeader),
  gridShared.documentDateHeader,
  gridShared.closedHeader,
  gridShared.sortable(gridShared.settleAmountCurHeader),
  gridShared.transTypeHeader,
  gridShared.dueDateHeader,
  gridShared.paymTermIdHeader,
  gridShared.txtHeader,
];
const route = useRoute();
const vpFinanceStore = useVpFinanceStore();
const protectExport = ref(true);
const loading = ref(false);
const title = computed(() => {
  return 'Payment Detail';
});
const transItems = computed(() => {
  if (vpFinanceStore.custSettlementResult) {
    const items = vpFinanceStore.custSettlementResult.Transactions.map(x => {
      const computed = vpFinanceStore.custSettlementResult.Computed.find(
        c => c.SourceKey === x.SourceKey,
      );
      return {
        ...x,
        SettleAmountCur: computed ? computed.SettleAmountCur : x.SettleAmountCur,
        Invoice: computed ? computed.Invoice : x.Invoice,
      };
    });
    return items;
  } else {
    return [];
  }
});
function exportToExcel() {
  vpFinanceStore.exportCustTransToExcel(
    voucherRoute.value,
    dataAreaIdRoute.value,
    protectExport.value,
  );
}
const dataAreaIdRoute = computed(() => {
  return getRouteValue(route.params.dataAreaId);
});
const voucherRoute = computed(() => {
  return getRouteValue(route.params.voucher);
});
function loadCustTrans() {
  if (dataAreaIdRoute.value !== 'undefined') {
    loading.value = true;
    vpFinanceStore
      .getCustSettlementsForTrans(voucherRoute.value, dataAreaIdRoute.value)
      .finally(() => {
        loading.value = false;
      });
  }
}
watch(
  [dataAreaIdRoute, voucherRoute],
  () => {
    loadCustTrans();
  },
  { immediate: true },
);
</script>
