<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <canvas id="signature" style="border: 2px solid black"></canvas>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="clear">{{
            props.buttonText ? props.buttonText : 'Clear Signature'
          }}</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import SignaturePad from 'signature_pad';
import { defineComponent, ref, onMounted } from 'vue';

const emit = defineEmits([
  'update:hasSignature',
  'update:signatureImageFile',
  'update:signatureImageDataUrl',
]);

const props = defineProps({
  signatureImageFile: File,
  signatureImageDataUrl: String,
  hasSignature: Boolean,
  buttonText: String,
  fileName: String,
  signaturePadWidth: Number,
  signaturePadHeight: Number,
});

defineComponent({
  name: 'Signature',
});

defineExpose({ clear });

const signaturePad = ref<SignaturePad>();

async function convertSignatureToFile(dataUrl: string) {
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  return new File([blob], props.fileName || 'signature.png', {
    type: 'image/png',
    lastModified: new Date().getTime(),
  });
}

function clear() {
  if (signaturePad.value) {
    signaturePad.value?.clear();
    emit('update:hasSignature', false);
    emit('update:signatureImageFile', null);
  } else {
    initSignaturePad();
  }
}

function initSignaturePad() {
  if (signaturePad.value) {
    signaturePad.value.clear();
  } else {
    const canvas = document.getElementById('signature') as HTMLCanvasElement;

    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = props.signaturePadWidth
      ? props.signaturePadWidth * ratio
      : (window.innerWidth / 2) * ratio;
    canvas.height = props.signaturePadHeight ? props.signaturePadHeight * ratio : 200 * ratio;
    if (canvas?.getContext('2d')?.scale) {
      canvas?.getContext('2d')?.scale(ratio, ratio);
    }

    if (canvas) {
      signaturePad.value = new SignaturePad(canvas, {
        backgroundColor: 'white',
      });

      signaturePad.value.addEventListener(
        'endStroke',
        async () => {
          const data = signaturePad.value?.toData();

          if (data && data.length > 0) {
            const dataUrl = signaturePad.value?.toDataURL();
            //signatureImageDataUrl
            emit('update:signatureImageDataUrl', dataUrl);
            if (dataUrl) {
              const signatureImageFile = await convertSignatureToFile(dataUrl);
              emit('update:signatureImageFile', signatureImageFile);
              emit('update:hasSignature', true);
            } else {
              emit('update:hasSignature', false);
            }
          } else {
            emit('update:hasSignature', false);
          }
        },
        { once: false },
      );
    }
  }
}

onMounted(() => {
  clear();
});
</script>

<style>
.disabled img {
  filter: sepia(0%) saturate(200%) brightness(50%) hue-rotate(330deg);
}
</style>
