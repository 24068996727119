import { Record } from "../fable-library-js.4.16.0/Types.js";
import { record_type, list_type, string_type } from "../fable-library-js.4.16.0/Reflection.js";
import { sortBy, removeAt, tryFindIndex, cons, sort, empty } from "../fable-library-js.4.16.0/List.js";
import { List_distinct } from "../fable-library-js.4.16.0/Seq2.js";
import { safeHash, equals, comparePrimitives, stringHash } from "../fable-library-js.4.16.0/Util.js";

export class ItemHierarchyLookup extends Record {
    constructor(id, LookupId, Hierarchy) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.Hierarchy = Hierarchy;
    }
}

export function ItemHierarchyLookup_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.ItemHierarchyLookup", [], ItemHierarchyLookup, () => [["id", string_type], ["LookupId", string_type], ["Hierarchy", list_type(string_type)]]);
}

export const initItemHierarchyLookup = new ItemHierarchyLookup("ItemHierarchyLookup", "ItemHierarchyLookup", empty());

export function addToHierarchy(hierarchy, hierarchyLookup) {
    return new ItemHierarchyLookup(hierarchyLookup.id, hierarchyLookup.LookupId, sort(List_distinct(cons(hierarchy, hierarchyLookup.Hierarchy), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), {
        Compare: comparePrimitives,
    }));
}

export function removeFromHierarchy(hierarchy, hierarchyLookup) {
    let _arg;
    return new ItemHierarchyLookup(hierarchyLookup.id, hierarchyLookup.LookupId, (_arg = tryFindIndex((h) => (hierarchy === h), hierarchyLookup.Hierarchy), (_arg == null) ? hierarchyLookup.Hierarchy : removeAt(_arg, hierarchyLookup.Hierarchy)));
}

export class PlanogramLocationGroup extends Record {
    constructor(id, LookupId, Groups) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.Groups = Groups;
    }
}

export function PlanogramLocationGroup_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.PlanogramLocationGroup", [], PlanogramLocationGroup, () => [["id", string_type], ["LookupId", string_type], ["Groups", list_type(string_type)]]);
}

export const initPlanogramLocationGroup = new PlanogramLocationGroup("PlanogramLocationGroupLookup", "PlanogramLocationGroupLookup", empty());

export function addPlanogramLocationGroup(group, planogramLocationGroup) {
    return new PlanogramLocationGroup(planogramLocationGroup.id, planogramLocationGroup.LookupId, sort(List_distinct(cons(group, planogramLocationGroup.Groups), {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }), {
        Compare: comparePrimitives,
    }));
}

export function removeFromPlanogramLocationGroup(group, planogramLocationGroup) {
    let _arg;
    return new PlanogramLocationGroup(planogramLocationGroup.id, planogramLocationGroup.LookupId, (_arg = tryFindIndex((g) => (group === g), planogramLocationGroup.Groups), (_arg == null) ? planogramLocationGroup.Groups : removeAt(_arg, planogramLocationGroup.Groups)));
}

export class DCPOSharePointSite extends Record {
    constructor(SiteId, WarehouseId, Description, SharePointSiteName, SharePointSiteId, SharePointDriveId) {
        super();
        this.SiteId = SiteId;
        this.WarehouseId = WarehouseId;
        this.Description = Description;
        this.SharePointSiteName = SharePointSiteName;
        this.SharePointSiteId = SharePointSiteId;
        this.SharePointDriveId = SharePointDriveId;
    }
}

export function DCPOSharePointSite_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.DCPOSharePointSite", [], DCPOSharePointSite, () => [["SiteId", string_type], ["WarehouseId", string_type], ["Description", string_type], ["SharePointSiteName", string_type], ["SharePointSiteId", string_type], ["SharePointDriveId", string_type]]);
}

export class DCPOSharePointLookup extends Record {
    constructor(id, LookupId, DCPOSharePointSites) {
        super();
        this.id = id;
        this.LookupId = LookupId;
        this.DCPOSharePointSites = DCPOSharePointSites;
    }
}

export function DCPOSharePointLookup_$reflection() {
    return record_type("pogona_vendor_domain.VendorLookup.DCPOSharePointLookup", [], DCPOSharePointLookup, () => [["id", string_type], ["LookupId", string_type], ["DCPOSharePointSites", list_type(DCPOSharePointSite_$reflection())]]);
}

export const initDCPOLookup = new DCPOSharePointLookup("DCPOSharePointLookup", "DCPOSharePointLookup", empty());

export function addDCPOSharePointSite(dcPOSharePointSite, dcPOLookup) {
    return new DCPOSharePointLookup(dcPOLookup.id, dcPOLookup.LookupId, sortBy((s) => s.Description, List_distinct(cons(dcPOSharePointSite, dcPOLookup.DCPOSharePointSites), {
        Equals: equals,
        GetHashCode: safeHash,
    }), {
        Compare: comparePrimitives,
    }));
}

export function removeDCPOSharePointSite(dcPOSharePointSite, dcPOLookup) {
    let _arg;
    return new DCPOSharePointLookup(dcPOLookup.id, dcPOLookup.LookupId, (_arg = tryFindIndex((s) => equals(dcPOSharePointSite, s), dcPOLookup.DCPOSharePointSites), (_arg == null) ? dcPOLookup.DCPOSharePointSites : removeAt(_arg, dcPOLookup.DCPOSharePointSites)));
}

