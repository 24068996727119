<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-btn :loading="props.loading" @click="exportToExcel">{{ props.buttonText }}</v-btn>
      </v-col>
      <v-col>
        <!-- Checkbox to determine if file should be protected -->
        <v-checkbox v-model="localProtectFile" label="Protect" />
      </v-col>
    </v-row>
  </div>
</template>
<script setup lang="ts">
import { defineComponent, ref, watch } from 'vue';
defineComponent({
  name: 'ExcelExport',
});
// Define click emit
const emit = defineEmits(['click', 'update:protectFile']);
type Props = {
  buttonText: string;
  loading: boolean;
  protectFile: boolean;
};
const props = withDefaults(defineProps<Props>(), {
  buttonText: 'Export to Excel',
  loading: false,
  protectFile: true,
});
const localProtectFile = ref(props.protectFile);
function exportToExcel() {
  emit('click');
}
watch(
  () => props.protectFile,
  newVal => {
    localProtectFile.value = newVal;
  },
);
watch(
  () => localProtectFile.value,
  newVal => {
    emit('update:protectFile', newVal);
  },
);
</script>
