<template>
  <core-signs-labels-view-template title="Printer Align">
    <core-view-section class="mb-4" title="Sheets">
      <v-row v-if="isPrintVersion6">
        <v-col>
          <v-dialog v-model="overridePrintVersion6Dialog" max-width="600px">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on"> Override Alignment for JsPrintManager 6</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Override Alignment</span>
              </v-card-title>
              <v-card-text
                >Are you sure you want to override your printer alignment for JSPrintManager version
                6? Pogona has detected you are using version 6. You will only need to do this once.
                <b
                  >Note: some additional aligning may be needed after clicking "Yes".</b
                ></v-card-text
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="overridePrintVersion6"
                  :disabled="overridePrintVersion6Loading"
                  :loading="overridePrintVersion6Loading"
                  >Yes</v-btn
                >
                <v-btn @click="overridePrintVersion6Dialog = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            id="specs"
            v-if="specs"
            v-model="specs.selected"
            :headers="specs.headers"
            :items="specs.items"
            :single-select="true"
            :loading="specs.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="specs.options"
            @click:row="selectSpecRow"
            @item-selected="selectedSpecRow"
            @update:sort-by="specs.get()"
            item-key="specId"
            show-select
            class="elevation-1 scrollable"
          >
            <template v-slot:body.append>
              <infinite-loader
                :pogonaTable="specs"
                :colspan="specs.headers.length"
              ></infinite-loader>
            </template>

            <template v-slot:top>
              <v-dialog v-model="specEditDialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Edit Margins</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form v-model="specFormValid">
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedSpec.marginTopInches"
                              label="Top"
                              type="number"
                              :rules="marginAdjusterRules"
                              step=".0001"
                              min="0"
                              max="10"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedSpec.marginLeftInches"
                              label="Left"
                              type="number"
                              :rules="marginAdjusterRules"
                              step=".0001"
                              min="0"
                              max="10"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedSpec.spaceBetweenInnerLabelsRightInches"
                              label="Space Right"
                              type="number"
                              :rules="marginAdjusterRules"
                              step=".0001"
                              min="0"
                              max="10"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedSpec.spaceBetweenInnerLabelsBottomInches"
                              label="Space Bottom"
                              type="number"
                              :rules="marginAdjusterRules"
                              step=".0001"
                              min="0"
                              max="10"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="saveSpec"
                      :loading="savingSpec"
                      :disabled="savingSpec || !specFormValid"
                      >Save</v-btn
                    >
                    <v-btn @click="closeSpecDialog">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="specResetConfirmDialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Reset</span>
                  </v-card-title>

                  <v-card-text>Are you sure you want to reset the print alignment?</v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="resetSpec" :loading="resettingSpec" :disabled="resettingSpec"
                      >Reset</v-btn
                    >
                    <v-btn @click="closeResetConfirmDialog">Cancel</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.isPortrait="{ item }">
              {{ item.isPortrait ? 'Yes' : 'No' }}
            </template>

            <template v-slot:item.edit="{ item }">
              <v-icon class="mr-2" @click="editSpec(item)">create</v-icon>
              <v-icon class="mr-2" @click="confirmResetSpec(item)">replay</v-icon>
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="specs"></infinite-paganation>
        </v-col>
      </v-row>
    </core-view-section>
    <core-view-section v-if="svgTemplates" title="Templates">
      <v-row>
        <v-col>
          <v-row justify="end">
            <label-preview-switch></label-preview-switch>
          </v-row>
          <v-row id="svgTemplateGrid">
            <v-col>
              <v-data-table
                v-model="svgTemplates.selected"
                :headers="svgTemplates.headers"
                :items="svgTemplates.items"
                :single-select="true"
                :loading="svgTemplates.loading"
                :items-per-page="50"
                :disable-pagination="true"
                :fixed-header="true"
                :options.sync="svgTemplates.options"
                item-key="templateId"
                show-select
                class="elevation-1 scrollable"
                @click:row="selectSvgRow"
                @item-selected="selectedSvgRow"
                @update:sort-by="svgTemplates.get"
              >
                <template v-slot:body.append>
                  <infinite-loader
                    :pogonaTable="svgTemplates"
                    :colspan="svgTemplates.headers.length"
                  ></infinite-loader>
                </template>

                <template v-slot:item.preview="{ item }">
                  <label-preview-lazy
                    v-model="item.previewIsActive"
                    :item="item"
                    :url="`//${$apiBasePath}label-render/render-batch-label-sample/${storeNumber}/${username}/${item.templateId}/1.svg`"
                    :headers="labelPreviewHeaders"
                  />
                </template>
              </v-data-table>
              <infinite-paganation :pogonaTable="svgTemplates"></infinite-paganation>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </core-view-section>

    <v-row v-if="showRenderSection">
      <v-col xl="3" lg="3" md="3" cols="12">
        <v-btn
          @click="renderPreview"
          :disabled="!svgTemplates || !svgTemplates.selected || svgTemplates.selected.length == 0"
          >Render Preview</v-btn
        >
      </v-col>
      <v-col xl="3" lg="3" md="3" cols="12">
        <v-checkbox v-model="drawBorders">
          <template v-slot:label>
            <label>Draw Borders</label>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row>
      <v-divider class="ma-8"></v-divider>
    </v-row>
    <v-row class="ma-2" v-if="renderLabelsData">
      <v-col>
        <render-progress
          id="rendering"
          :autoCompleteBatch="false"
          :renderLabelsData="renderLabelsData"
          @started="renderStarted"
        ></render-progress>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <data-tour
        tourName="printerAlignTour"
        :steps="tourSteps"
        maxWidth="600px"
        :show="true"
      ></data-tour>
    </v-row>
  </core-signs-labels-view-template>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import moment from 'moment';
import PogonaDataTable from '@/utils/PogonaDataTable';
import RenderLabelData from '@/data/SignsAndLabels/RenderLabelData';
import FooterInformation from '@/data/SignsAndLabels/FooterInformation';
import SampleRender from '@/data/SignsAndLabels/SampleRender';
import LabelPreviewLazy from './LabelPreviewLazy.vue';
import RenderProgress from './RenderProgressComponent.vue';

export default {
  components: {
    LabelPreviewLazy,
    RenderProgress,
  },
  data() {
    return {
      marginAdjusterRules: [
        v => {
          if (v === null || v === undefined) {
            return 'Please enter a value';
          }

          return true;
        },
      ],
      specs: null,
      svgTemplates: null,
      selectedSpec: null,
      editedSpec: {},
      specEditDialog: false,
      savingSpec: false,
      specResetConfirmDialog: false,
      resettingSpec: false,
      specFormValid: false,
      selectedSvg: null,
      renderLabelsData: null,
      drawBorders: false,
      labelPreviewHeaders: {
        'x-api-host': this.$apiHostAndProtocol,
      },
      tourSteps: [
        {
          target: 'h4:first-child',
          header: {
            title: 'Printer Align Video Tour',
          },
          video: 'printer_align',
          placement: 'bottom',
        },
      ],
      isPrintVersion6: false,
      overridePrintVersion6Dialog: false,
      overridePrintVersion6Loading: false,
    };
  },
  methods: {
    async getData() {
      this.renderLabelsData = null;
      this.svgTemplates = null;
      this.initSpecTable();
      await this.getSpecs();
    },
    initSpecTable() {
      this.specs = new PogonaDataTable({
        headers: [
          {
            text: 'Description',
            value: 'description',
          },
          {
            text: 'Margin Top (Inches)',
            value: 'marginTopInches',
            sortable: false,
          },
          {
            text: 'Margin Left (Inches)',
            value: 'marginLeftInches',
            sortable: false,
          },
          {
            text: 'Space Between Right (Inches)',
            value: 'spaceBetweenInnerLabelsRightInches',
            sortable: false,
          },
          {
            text: 'Space Between Bottom (Inches)',
            value: 'spaceBetweenInnerLabelsBottomInches',
            sortable: false,
          },
          {
            text: 'Rows',
            value: 'labelRows',
            sortable: false,
          },
          {
            text: 'Columns',
            value: 'labelColumns',
            sortable: false,
          },
          {
            text: 'Portrait',
            value: 'isPortrait',
          },
          { text: 'Edit', value: 'edit', sortable: false },
        ],
        baseUrl: `label/usernameoverridesheetspecification/user/${this.username}`,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['specID'] },
        alwaysGetData: true,
        isInfinite: true,
      });
      this.specs.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting printer specs',
          err,
          id: '3eddeca7-6322-4e24-a628-61f79800eb50',
        });
      });
    },
    selectSpecRow(event) {
      this.specs.selected = [event];
      this.selectedSpecRow({ item: event, value: true });
    },
    selectedSpecRow(event) {
      if (event.value === false) {
        this.svgTemplates = null;
        this.selectedSpec = null;
      } else {
        this.renderLabelsData = null;
        this.selectedSpec = event.item;
        this.svgTemplates = this.initSvgTemplates();
        this.svgTemplates.get();
      }
    },
    async getSpecs() {
      await this.specs.get();
    },
    editSpec(item) {
      this.editedSpec = Object.assign({}, item);
      this.specEditDialog = true;
    },
    confirmResetSpec(item) {
      this.editedSpec = Object.assign({}, item);
      this.specResetConfirmDialog = true;
    },
    async resetSpec() {
      // show dialog confirming they want to reset the spec
      // then just delete override spec
      try {
        this.resettingSpec = true;
        await this.$authApi.http.delete(
          `label/usernameoverridesheetspecification/${this.editedSpec.specId}/${this.username}`,
        );
      } catch (err) {
        // we may have 404's here.
        // if there isn't an override in the table, a 404 is thrown.
        // in those cases, we don't want to show an error message
        if (err.message.indexOf('404') === -1) {
          this.$emit('snackbar-error', {
            text: 'Error resetting printer alignment.',
            err,
            id: 'a0316464-2b97-4fd0-87b8-a5a92c9fd750',
          });
        }
      } finally {
        await this.afterSpecUpdate();
        this.editedSpec = {};
        this.resettingSpec = false;
        this.closeResetConfirmDialog();
      }
    },
    async saveSpec() {
      try {
        this.savingSpec = true;
        await this.$authApi.http.patch(
          `label/usernameoverridesheetspecification/user/${this.username}/${this.editedSpec.specId}`,
          this.editedSpec,
        );
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error updating printer alignment.',
          err,
          id: 'b8c701e6-ee20-48b0-81ac-722e9169ec84',
        });
      } finally {
        await this.afterSpecUpdate();
        this.editedSpec = {};
        this.savingSpec = false;
        this.closeSpecDialog();
      }
    },
    async afterSpecUpdate() {
      this.initSpecTable();
      await this.getSpecs();
      this.svgTemplates = null;

      // auto select the spec row
      const editedSpecInItems = this.specs.items.filter(x => x.specId === this.editedSpec.specId);
      if (editedSpecInItems && editedSpecInItems.length === 1) {
        this.selectSpecRow(editedSpecInItems[0]);
      }
    },
    closeSpecDialog() {
      this.specEditDialog = false;
    },
    closeResetConfirmDialog() {
      this.specResetConfirmDialog = false;
    },
    selectSvgRow(event) {
      this.svgTemplates.selected = [event];
      this.selectedSvgRow({ item: event, value: true });
    },
    selectedSvgRow(event) {
      this.renderLabelsData = null;
      this.selectedSvg = event.item;
    },
    renderPreview() {
      // Build up render data
      const footerInformation = new FooterInformation(
        {
          batchDescription: 'SAMPLE BATCH',
          beginDate: moment().format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
        'SAMPLE',
        this.storeNumber,
        'Printer Align',
      );
      const sampleRender = new SampleRender(
        this.storeNumber,
        this.selectedSvg.templateId,
        this.selectedSpec.labelRows * this.selectedSpec.labelColumns,
        this.drawBorders,
      );
      const renderLabelsData = new RenderLabelData(
        null,
        1,
        true,
        false,
        null,
        null,
        this.username,
        footerInformation,
        sampleRender,
        null,
        true,
        false,
        false,
        '',
        true,
      );

      this.renderLabelsData = null;
      // Forces a new render
      setTimeout(() => {
        this.renderLabelsData = renderLabelsData;
      }, 0);
    },
    renderStarted() {
      this.$vuetify.goTo('#rendering');
    },
    initSvgTemplates() {
      const svgTemplates = new PogonaDataTable({
        headers: [
          {
            text: 'Description',
            value: 'description',
          },
          { text: 'Preview', value: 'preview', sortable: false },
        ],
        baseUrl: `label/svgtemplate/templates-for-align/${this.storeNumber}/${this.selectedSpec.specId}`,
        httpClient: this.$authApi.http,
        options: {
          itemsPerPage: 50,
          sortBy: ['templateId'],
          select: ['templateId', 'description'],
        },
        isInfinite: true,
      });

      svgTemplates.on('dataBound', () => {
        this.$vuetify.goTo('#svgTemplateGrid');

        // eslint-disable-next-line no-restricted-syntax
        for (const item of svgTemplates.items) {
          Vue.set(item, 'previewIsActive', false);
        }
      });

      svgTemplates.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting label templates',
          err,
          id: '36921d95-6cdf-458a-94de-d0ddd41e1d81',
        });
      });

      return svgTemplates;
    },
    async probeForJsprintVersion6() {
      let ws = null;
      var probe = new Promise(resolveInner => {
        ws = new WebSocket('wss://localhost:26443');
        ws.onopen = () => {
          resolveInner(true);
        };
        ws.onclose = () => {
          resolveInner(false);
        };
      });

      const result = await probe;
      if (ws) {
        ws.close();
      }

      if (result === true) {
        this.isPrintVersion6 = true;
      }
    },
    async overridePrintVersion6() {
      try {
        this.overridePrintVersion6Loading = true;
        await this.$authApi.http.post(
          `label/usernameoverridesheetspecification/user/${this.username}/v6`,
        );
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error overriding alignment for JSPrintManager version 6.',
          err,
          id: 'd6f14a32-5789-49b6-af51-c22fc9ac39a5',
        });
      } finally {
        await this.afterSpecUpdate();
        this.overridePrintVersion6Dialog = false;
        this.overridePrintVersion6Loading = false;
      }
    },
  },
  computed: {
    ...mapState('app', ['storeNumber', 'username']),
    showRenderSection() {
      return (
        this.specs &&
        this.specs.selected.length === 1 &&
        this.svgTemplates &&
        this.svgTemplates.selected.length === 1
      );
    },
  },
  async created() {
    await this.getData();
    await this.probeForJsprintVersion6();
  },
  watch: {
    storeNumber: {
      async handler() {
        await this.getData();
      },
    },
    'svgTemplates.items': {
      handler(val) {
        // if only one item, auto select it
        if (val && val.length === 1) {
          this.selectSvgRow(this.svgTemplates.items[0]);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
#sheet {
  border: 1px solid $black-color;
}
b {
  font-size: 1em;
}
</style>
