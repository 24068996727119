<template>
  <core-view-template title="Invoice Detail">
    <div>
      <show-impersonation />
      <v-row class="mb-2">
        <v-col cols="8">
          <invoice-search></invoice-search>
        </v-col>
        <v-col cols="2">
          <ExcelExport
            buttonText="Export"
            :loading="loading"
            @click="exportToExcel"
            :protectFile="protectExport"
            @update:protectFile="val => (protectExport = val)"
          />
        </v-col>
      </v-row>
      <h3 v-if="!loading && !invoiceFound">Invoice {{ $route.params.invoiceNumber }} not found</h3>
      <h4>Invoice Header</h4>
      <v-data-table
        :headers="headers"
        :items="salesInvoiceHeaders"
        :loading="loading"
        hide-default-footer
      >
        <template v-slot:item.InvoiceDate="{ item }">
          {{ formatters.formatDate(item.InvoiceDate) }}</template
        >
        <template v-slot:item.InvoiceNumber="{ item }">
          <span class="no-wrap">{{ item.InvoiceNumber }}</span></template
        >
        <template v-slot:item.TotalInvoiceAmount="{ item }">
          {{ formatters.formatCurrency(item.TotalInvoiceAmount) }}</template
        >
        <template v-slot:item.TotalTaxAmount="{ item }">
          {{ formatters.formatCurrency(item.TotalTaxAmount) }}</template
        >
        <template v-slot:item.DataAreaId="{ item }">
          <span class="no-wrap">{{ getLegalEntityLookup(item.DataAreaId) }}</span>
        </template>
        <template v-slot:item.DeliveryAddressName="{}">
          <span class="no-wrap">{{ salesOrderDeliveryAddressName }}</span></template
        >
        <template v-slot:item.FormattedDeliveryAddress="{}">
          <span class="no-wrap">{{ salesOrderFormattedDeliveryAddress }}</span></template
        >
      </v-data-table>
      <h4 class="mt-3">Invoice Lines</h4>
      <v-data-table
        :headers="invoiceLineHeaders"
        :items="vpFinanceStore.salesInvoiceLines"
        :loading="loading"
        :items-per-page="vpFinanceStore.salesInvoiceLines.length"
        hide-default-footer
      >
        <template v-slot:item.InvoiceDate="{ item }">
          {{ formatters.formatDate(item.InvoiceDate) }}</template
        >
        <template v-slot:item.ConfirmedShippingDate="{ item }">
          {{ formatters.formatDate(item.ConfirmedShippingDate) }}</template
        >
        <template v-slot:item.InvoiceNumber="{ item }">
          <span class="no-wrap">{{ item.InvoiceNumber }}</span></template
        >
        <template v-slot:item.LineAmount="{ item }">
          {{ formatters.formatCurrency(item.LineAmount) }}</template
        >
        <template v-slot:item.LineTotalTaxAmount="{ item }">
          {{ formatters.formatCurrency(item.LineTotalTaxAmount) }}</template
        >
        <template v-slot:item.DataAreaId="{ item }">
          <span class="no-wrap">{{ getLegalEntityLookup(item.DataAreaId) }}</span>
        </template>
      </v-data-table>
    </div>
  </core-view-template>
</template>

<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useVpFinanceStore } from '@/stores/vpfinancestore';
import InvoiceSearch from '@/components/vendorportal/InvoiceSearch.vue';
import { useRouter } from 'vue-router/composables';
import { useLegalEntityLookup } from '@/composables/financeComposables';
import ShowImpersonation from '@/components/vendorportal/ShowImpersonation.vue';
import { useFormatters } from '@/composables/formatters';
import ExcelExport from '@/components/vendorportal/ExcelExport.vue';
defineComponent({
  name: 'InvoiceDetail',
});

const router = useRouter();
const formatters = useFormatters();
const vpFinanceStore = useVpFinanceStore();
const { getLegalEntityLookup } = useLegalEntityLookup();
const loading = ref(false);
const protectExport = ref(true);
const headers = [
  { text: 'Company', value: 'DataAreaId', sortable: false },
  { text: 'Customer Account Number', value: 'InvoiceCustomerAccountNumber', sortable: false },
  { text: 'Invoice Number', value: 'InvoiceNumber', sortable: false },
  { text: 'Invoice Date', value: 'InvoiceDate', sortable: false },
  { text: 'Total Invoice Amount', value: 'TotalInvoiceAmount', sortable: false, align: 'end' },
  { text: 'Total Tax Amount', value: 'TotalTaxAmount', sortable: false, align: 'end' },
  { text: 'Delivery Address Name', value: 'DeliveryAddressName', sortable: false },
  { text: 'Delivery Address', value: 'FormattedDeliveryAddress', sortable: false },
];

const invoiceLineHeaders = [
  { text: 'Company', value: 'DataAreaId', sortable: false },
  { text: 'Invoice Number', value: 'InvoiceNumber', sortable: false },
  { text: 'Invoice Date', value: 'InvoiceDate', sortable: false },
  { text: 'Product Number', value: 'ProductNumber', sortable: false },
  { text: 'Product Name', value: 'ProductName', sortable: false },
  { text: 'Product Description', value: 'ProductDescription', sortable: false },
  { text: 'Invoiced Quantity', value: 'InvoicedQuantity', sortable: false },
  { text: 'Sales Unit Symbol', value: 'SalesUnitSymbol', sortable: false },
  { text: 'Line Amount', value: 'LineAmount', sortable: false, align: 'end' },
  { text: 'Line Total Tax Amount', value: 'LineTotalTaxAmount', sortable: false, align: 'end' },
  { text: 'Inventory Site Id', value: 'InventorySiteId', sortable: false },
  { text: 'Inventory Warehouse Id', value: 'InventoryWarehouseId', sortable: false },
];

const salesOrderDeliveryAddressName = computed(() => {
  return vpFinanceStore.invoiceData?.OperatingUnitName;
});
const salesOrderFormattedDeliveryAddress = computed(() => {
  return vpFinanceStore.invoiceData?.FullPrimaryAddress;
});

const salesInvoiceHeaders = computed(() => {
  return vpFinanceStore.salesInvoiceHeader ? [vpFinanceStore.salesInvoiceHeader] : [];
});

const invoiceFound = computed(() => {
  return vpFinanceStore.salesInvoiceHeader;
});

function exportToExcel() {
  vpFinanceStore.getInvoiceExcel(router.currentRoute.params.invoiceNumber, protectExport.value);
}

function loadInvoice() {
  vpFinanceStore.salesInvoiceHeader = null;
  vpFinanceStore.salesInvoiceLines = [];
  loading.value = true;
  vpFinanceStore
    .getSalesInvoice(router.currentRoute.params.invoiceNumber)
    .finally(() => (loading.value = false));
}

// Watch route parameter invoiceNumber change
watch(
  () => router.currentRoute.params.invoiceNumber,
  () => {
    loadInvoice();
  },
);

// Call loadInvoice when mounted
onMounted(() => {
  loadInvoice();
});
</script>

<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap !important;
}
</style>
