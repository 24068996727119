/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Store } from 'vuex';

export interface AllStores {
  app: {
    showStoreNumber: boolean;
    storeNumber: string;
  };
}
export function formatApp(target: string): string {
  return `app/${target}`;
}
export function dispatchApp(store: Store<AllStores>, action: string, payload: any): Promise<any> {
  return store.dispatch(formatApp(action), payload);
}
export function commitApp(store: Store<AllStores>, mutation: string, payload: any): void {
  store.commit(formatApp(mutation), payload);
}
