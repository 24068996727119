<template>
  <core-view-template title="New Item Batch Search">
    <batch-search-data-table
      @snackbar-warning="emit('snackbar-warning', $event)"
      @snackbar-error="emit('snackbar-error', $event)"
    />
  </core-view-template>
</template>
<script setup lang="ts">
import { defineComponent, defineEmits } from 'vue';
import BatchSearchDataTable from '@/components/vendorportal/BatchSearchDataTable.vue';
defineComponent({
  components: { BatchSearchDataTable },
});
const emit = defineEmits(['snackbar-warning', 'snackbar-error']);
</script>
