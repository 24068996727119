<template>
  <core-view-template :title="title">
    <v-container>
      <v-row>
        <v-col
          ><h4>Journal {{ journalId }}</h4></v-col
        >
        <v-col class="d-flex justify-end">
          <v-btn @click="exportJournal">Export</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="journalHeadersComputed"
            :items="journalRow"
            :loading="loading"
            :hide-default-footer="true"
          >
            <template v-slot:item.MobileJournalTypeId="{ item }">
              {{ JournalType[item.MobileJournalTypeId] }}
            </template>
            <template v-slot:item.CreatedTime="{ item }">
              <span class="no-wrap">{{ formatters.formatDatetime(item.CreatedTime, false) }}</span>
            </template>
            <template v-slot:item.Complete="{ item }">
              {{ item.Complete ? 'Yes' : 'No' }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Journal Lines</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-text-field
            v-model="lineSearch"
            append-icon="mdi-magnify"
            label="Line Search"
            persistent-hint
            clearable
            ref="lineSearchRef"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="journalLineHeadersComputed"
            :items="handheldstore.journalLines"
            :loading="loading"
            :hide-default-footer="true"
            :items-per-page="-1"
            :search="lineSearch"
          >
            <template v-slot:item.ModifiedTime="{ item }">
              <span class="no-wrap">{{ formatters.formatDatetime(item.ModifiedTime, false) }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="editLine(item)">mdi-pencil</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="500">
        <v-card>
          <v-card-title>
            <span class="headline">Edit Quantity</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Item ID" v-model="editedItem.ItemId" readonly />
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Barcode" v-model="editedItem.Barcode" readonly />
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Product Name" v-model="editedItem.ProductName" readonly />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    autofocus
                    label="Quantity"
                    v-model.number="editedItem.Quantity"
                    type="number"
                    min="0"
                    @input="validateQuantity"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="saving" :disabled="!quantityChanged" @click="saveChanges">
              Save
            </v-btn>
            <v-btn @click="closeDialog"> Cancel </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { JournalOptions, journalOptionsFactory } from '@/utils/JournalOptions';
import { computed, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import { useFormatters } from '@/composables/formatters';
import { useHandHeldStore } from '@/stores/handheldstore';
import { JournalType } from '@/stores/handheldstore';
const journalHeaders = [
  { text: 'Journal ID', value: 'MobileJournalId', sortable: false },
  { text: 'Journal Type', value: 'MobileJournalTypeId', sortable: false },
  { text: 'Date', value: 'CreatedTime', sortable: false },
  { text: 'Store', value: 'StoreId', sortable: false },
  { text: 'Submitted', value: 'Complete', sortable: false },
];
const journalLineHeaders = [
  { text: 'Item ID', value: 'ItemId', sortable: true, align: 'end' },
  { text: 'Barcode', value: 'Barcode', sortable: true },
  { text: 'Quantity', value: 'Quantity', sortable: true, align: 'center' },
  { text: 'Product Name', value: 'ProductName', sortable: true },
  { text: 'Description', value: 'Description', sortable: true },
  { text: 'Pack Size', value: 'PackSize', sortable: true, align: 'center' },
  { text: 'Modified Time', value: 'ModifiedTime', sortable: true, filterable: false },
];
const handheldstore = useHandHeldStore();
const journalHeadersComputed = computed(() => {
  return [...journalHeaders, ...(journalOptions.value?.gridHeaders ?? [])];
});
const journalLineHeadersComputed = computed(() => {
  return [
    ...journalLineHeaders,
    ...(journalOptions.value?.lineHeaders ?? []),
    ...(handheldstore.journal && !handheldstore.journal.Complete
      ? [{ text: 'Actions', value: 'actions', sortable: false }]
      : []),
  ];
});
const title = ref('Journal Detail');
const formatters = useFormatters();
const lineSearchRef = ref<HTMLInputElement | null>(null);
const route = useRoute();
const journalOptions = ref<JournalOptions | null>(null);
const journalId = ref(route.params.journalId);
const journalType = ref(route.params.journalType);
const loading = ref(false);
const lineSearch = ref('');
const dialog = ref(false);
const originalQuantity = ref(-1);
const saving = ref(false);
const editedItem = reactive({
  MobileJournalLineId: 0,
  ItemId: null,
  Barcode: '',
  ProductName: '',
  Quantity: 0,
});
const closeDialog = () => {
  dialog.value = false;
};
async function editLine(item) {
  console.log('editLine', item);
  editedItem.MobileJournalLineId = item.MobileJournalLineId;
  editedItem.ItemId = item.ItemId;
  editedItem.Barcode = item.Barcode;
  editedItem.ProductName = item.ProductName;
  editedItem.Quantity = item.Quantity;
  originalQuantity.value = item.Quantity;
  dialog.value = true;
}
const saveChanges = async () => {
  try {
    saving.value = true;
    await handheldstore.updateJournalLineQuantity(
      editedItem.MobileJournalLineId,
      editedItem.Quantity,
    );
  } finally {
    saving.value = false;
    closeDialog();
    getJournalDetail();
  }
};
const quantityChanged = computed(() => {
  return editedItem.Quantity !== originalQuantity.value;
});
const validateQuantity = () => {
  if (editedItem.Quantity < 0) {
    editedItem.Quantity = 0;
  }
};
async function exportJournal() {
  if (journalId.value) {
    await handheldstore.journalDetailExport(parseInt(journalId.value));
  }
}
const journalRow = computed(() => {
  if (handheldstore.journal) {
    return [handheldstore.journal];
  } else {
    return [];
  }
});
async function getJournalDetail() {
  try {
    loading.value = true;
    if (journalId.value && journalOptions.value) {
      const journalPromise = handheldstore.getJournal(
        parseInt(journalId.value),
        journalOptions.value,
      );
      const linesPromise = handheldstore.getJournalLines(parseInt(journalId.value));
      await Promise.all([journalPromise, linesPromise]);
    }
  } finally {
    loading.value = false;
  }
}
onMounted(async () => {
  if (lineSearchRef.value) {
    lineSearchRef.value.focus();
  }
  journalOptions.value = journalOptionsFactory(parseInt(journalType.value));
  await getJournalDetail();
});
</script>
<style lang="scss" scoped>
.no-wrap {
  white-space: nowrap;
}
</style>
