import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as hhdto from '@psp/pogohdto';
import buildQuery from 'odata-query';
import store from '@/store';
import { useGlobalStore } from './globalstore';
import { JournalOptions, PogohJournalType } from '@/utils/JournalOptions';
import FileSaver from 'file-saver';

export enum JournalType {
  PurchaseOrder = 1,
  TransferOrder = 2,
  ReturnOrder = 3,
  Adjustment = 4,
  QuickCount = 5,
  Movement = 6,
  LabelsAndSigns = 7,
  LiveQuickCount = 8,
}

export class JournalSearchParams {
  public journalTypeId: JournalType | null = null;
  public storeId: string = '';
  public complete: boolean | null = null;
  public fromDate: Date | null = null;
  public toDate: Date | null = null;
  public skip: number = 0;
  public take: number = 10;

  constructor(init?: Partial<JournalSearchParams>) {
    Object.assign(this, init);
  }
}

export const useHandHeldStore = defineStore('handheldstore', () => {
  const globalStore = useGlobalStore();
  const journals = ref<hhdto.pogohMobileJournal[]>([]);
  const journal = ref<PogohJournalType | null>(null);
  const journalLines = ref<hhdto.pogohMobileJournalLine[]>([]);

  async function getJournal(journalId: number, options: JournalOptions) {
    try {
      const query = buildQuery({
        filter: { MobileJournalId: journalId },
      });
      const response = await globalStore.authClient.client.http.get(
        `./handheldapi/${options.journalDtoEndpoint}${query}`,
      );
      if (response.data.length === 1) {
        journal.value = response.data[0];
      } else {
        journal.value = null;
      }
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `getJournal failed error: ${JSON.stringify(error)} journalId: ${journalId}`,
        id: '5664828a-26a4-40e6-adf1-babece9aad07',
      });
    }
  }

  async function getJournalLines(journalId: number) {
    try {
      const query = buildQuery({
        filter: { MobileJournalId: journalId },
        orderBy: 'ModifiedTime desc',
      });
      const response = await globalStore.authClient.client.http.get(
        `./handheldapi/MobileJournalLine${query}`,
      );
      journalLines.value = response.data;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `getJournalLines failed error: ${JSON.stringify(error)} journalId: ${journalId}`,
        id: '31952005-d0ca-4ccc-8ef8-3485a78f1e59',
      });
    }
  }

  async function searchJournals(search: JournalSearchParams) {
    try {
      if (!store.state.app.storeNumber) {
        throw new Error('Store number is not set');
      }
      search.storeId = store.state.app.storeNumber;
      const baseQuery = { top: search.take, skip: search.skip, orderBy: 'MobileJournalId desc' };
      const query = buildQuery({
        ...baseQuery,
        filter: {
          ...(search.journalTypeId !== null && { MobileJournalTypeId: search.journalTypeId }),
          ...(search.storeId && { StoreId: search.storeId }),
          ...(search.complete !== null && { Complete: search.complete }),
          ...((search.fromDate || search.toDate) && {
            CreatedTime: {
              ...(search.fromDate && { ge: search.fromDate }),
              ...(search.toDate && { le: search.toDate }),
            },
          }),
        },
      });
      const response = await globalStore.authClient.client.http.get(
        `./handheldapi/MobileJournal${query}`,
      );
      journals.value = response.data;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `searchJournals failed error: ${JSON.stringify(error)}`,
        id: '3bfa7e4a-9a01-4e0b-8057-d8c6cb58b4fa',
      });
    }
  }
  async function journalDetailExport(journalId: number) {
    try {
      const response = await globalStore.authClient.client.http.get(
        `./handheldapi/handheld/JournalDetailExport/${journalId}`,
        { responseType: 'blob' },
      );
      FileSaver.saveAs(response.data, `JournalDetailExport_${journalId}.xlsx`);
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `journalDetailExport failed error: ${JSON.stringify(error)} journalId: ${journalId}`,
        id: 'ba2d404b-d090-4467-ab3e-ccefaa01854d',
      });
    }
  }
  async function updateJournalLineQuantity(journalLineId: number, quantity: number) {
    try {
      const response = await globalStore.authClient.client.http.patch(
        `./handheldapi/MobileJournalLine/${journalLineId}`,
        { Quantity: quantity },
      );
      return response.data;
    } catch (error) {
      store.commit('setError', {
        err: error,
        text: `updateJournalLineQuantity failed error: ${JSON.stringify(error)} journalLineId: ${journalLineId} quantity: ${quantity}`,
        id: 'a0cf3b6a-4c06-454c-b7e6-f7762c24cf31',
      });
    }
  }
  return {
    journal,
    journals,
    journalLines,
    getJournal,
    getJournalLines,
    searchJournals,
    journalDetailExport,
    updateJournalLineQuantity,
  };
});
