import { getCurrentInstance } from 'vue';
import { Store } from 'vuex';
import VueRouter, { Route } from 'vue-router';

export function useStore<T = any>(): Store<T> {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useStore must be called within a setup function');
  }
  return vm.proxy.$store;
}

export function useRouter(): VueRouter {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useRouter must be called within a setup function');
  }
  return vm.proxy.$router;
}

export function useRoute(): Route {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('useRoute must be called within a setup function');
  }
  return vm.proxy.$route;
}
