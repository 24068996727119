export class NewItemRequestData {
  AggregateId = '';
  PrivateLabel = false;
  RequestID = 0;
  RequestType = '';
  Status = '';
  FullUnitBarcode = '';
  CaseBarcode = '';
  OtherBarcode = '';
  ProductName = '';
  RetailPackageSize = '';
  RetailPackageUOM = '';
  ManufacturerAccountNumber = '';
  Brand = '';
  VendorId = '';
  ExternalItemNumber = '';
  MasterCase = 0;
  InnerCasePack = 0;
  LayerQuantity = 0;
  PalletQuantity = 0;
  DistributionNetwork = '';
  DirectToStore = '';
  DirectToStoreOrVendorIntoPspdPurchaseCost = 0;
  MfgSuggestedRetail = 0;
  MapPrice = 0;
  IMap = 0;
  AutoShipEligible = false;
  InitialDiscountFundingPercent = 0;
  OnGoingFundingDiscountPercent = 0;
  MaterialSafetyDataSheetNeeded = false;
  MinimumOrderQuantity = 0;
  UnitWeight = 0;
  UnitWeightUOM = '';
  UnitHeight = 0;
  UnitWidth = 0;
  UnitDepth = 0;
  CaseHeight = 0;
  CaseWidth = 0;
  CaseDepth = 0;
  ProductAttribute = '';
  Images = [];
  ProgramName = '';
  HtsCode = 0;
  DutyRate = 0;
  FobPort = '';
  PluScan = '';
  PluAssigned = '';
  DaxItemNumber = '';
  SignsAndLabelsDescription1 = '';
  SignsAndLabelsDescription2 = '';
  ShipsToStores = '';
  ShipUnitCostFromPspd = 0;
  VendorNumber = '';
  RetailBasePrice = 0;
  RetailHighPrice = 0;
  Hierarchy = '';
  PlanogramLocationGroup = '';
  CategoryManagerName = '';
  PspdInventoryManagerName = '';
  Reason = '';
  Distributors = null;
  IsMulti = false;
  IsLast = false;
  BatchId = '';
  NewUPC = '';
  AnticipatedDateOfItemChange = '';
  Seasonal = '';
  Season = '';
  Year = '';
  Category = '';
}
