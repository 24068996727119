<template>
  <core-view-template title="Journal List">
    <v-container>
      <v-row class="rowCursor">
        <v-col
          ><v-select
            :items="journalTypeOptions"
            v-model="selectedJournalType"
            label="Select Journal Type"
            :clearable="true"
            item-value="value"
            item-text="text"
          />
        </v-col>
        <v-col>
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDateText"
                label="From Date"
                readonly
                v-on="on"
                persistent-hint
                clearable
                @click:clear="fromDate = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              no-title
              @input="fromDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-menu
            v-model="toDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDateText"
                label="To Date"
                readonly
                v-on="on"
                persistent-hint
                clearable
                @click:clear="toDate = null"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" no-title @input="toDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-select
            :items="completeOptions"
            v-model="completeSelected"
            label="Submitted"
            :clearable="true"
            item-value="value"
            item-text="text"
          />
        </v-col>
        <v-col>
          <v-btn @click="searchClick">Search</v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="journals"
        :items-per-page="10"
        :loading="loading"
        class="elevation-1 row-pointer"
        :options.sync="options"
        :server-items-length="serverItemsLength"
        :page="page"
        :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
        @click:row="rowClick"
      >
        <template v-slot:item.CreatedTime="{ item }">
          {{ formatters.formatDatetime(item.CreatedTime, false) }}
        </template>
        <template v-slot:item.MobileJournalTypeId="{ item }">
          {{ JournalType[item.MobileJournalTypeId] }}
        </template>
        <template v-slot:item.Complete="{ item }"> {{ item.Complete ? 'Yes' : 'No' }} </template>
        <template v-slot:footer.page-text="{}"> Page {{ page }} </template>
      </v-data-table>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { JournalSearchParams, JournalType, useHandHeldStore } from '@/stores/handheldstore';
import { AllStores, dispatchApp } from '@/utils/AllStores';
import { useStore } from '@/utils/CompositionApiHelpers';
import { useFormatters } from '@/composables/formatters';
import { useRouter } from 'vue-router/composables';
const handHeldStore = useHandHeldStore();
const store = useStore<AllStores>();
const journals = computed(() => handHeldStore.journals);
const headers = [
  { text: 'Journal ID', value: 'MobileJournalId', sortable: false },
  { text: 'Journal Type', value: 'MobileJournalTypeId', sortable: false },
  { text: 'Date', value: 'CreatedTime', sortable: false },
  { text: 'Store', value: 'StoreId', sortable: false },
  { text: 'Submitted', value: 'Complete', sortable: false },
];
const journalTypeOptions = [
  { value: null, text: 'No Selection' },
  { value: JournalType.Adjustment, text: 'Adjustment' },
  { value: JournalType.LabelsAndSigns, text: 'Labels & Signs' },
  { value: JournalType.LiveQuickCount, text: 'Live Quick Count' },
  { value: JournalType.Movement, text: 'Movement' },
  { value: JournalType.PurchaseOrder, text: 'Purchase Order' },
  { value: JournalType.QuickCount, text: 'Quick Count' },
  { value: JournalType.ReturnOrder, text: 'Return Order' },
  { value: JournalType.TransferOrder, text: 'Transfer Order' },
];
const completeOptions = [
  { value: null, text: 'No Selection' },
  { value: true, text: 'Yes' },
  { value: false, text: 'No' },
];
const formatters = useFormatters();
const router = useRouter();
const completeSelected = ref<boolean | null>(false);
const loading = ref(false);
const selectedJournalType = ref<JournalType | null>(null);
const skip = ref(0);
const take = ref(10);
const options = ref<any>({});
const serverItemsLength = ref(take.value + 1);
const page = ref(1);
const fromDate = ref<Date | null>(null);
const fromDateMenu = ref(false);
const fromDateText = ref('');
const toDate = ref<Date | null>(null);
const toDateMenu = ref(false);
const toDateText = ref('');
const storeId = computed(() => {
  return store.state.app.storeNumber;
});
function rowClick(item: any) {
  router.push({
    name: 'handheld_journaldetail',
    params: { journalId: item.MobileJournalId, journalType: item.MobileJournalTypeId },
  });
}
async function searchClick() {
  page.value = 1;
  skip.value = 0;
  serverItemsLength.value = take.value + 1;
  await searchJournals();
}
async function searchJournals() {
  try {
    loading.value = true;
    await handHeldStore.searchJournals(
      new JournalSearchParams({
        skip: skip.value,
        take: take.value,
        journalTypeId: selectedJournalType.value,
        fromDate: fromDate.value ? new Date(fromDate.value) : null,
        toDate: toDate.value ? new Date(toDate.value) : null,
        complete: completeSelected.value,
      }),
    );
  } finally {
    loading.value = false;
    serverItemsLength.value =
      (page.value - 1) * take.value +
      journals.value.length +
      (journals.value.length === take.value ? 1 : 0);
  }
}
watch(options, async () => {
  if ('itemsPerPage' in options.value) {
    page.value = options.value.page;
    take.value = options.value.itemsPerPage;
    skip.value = (options.value.page - 1) * take.value;
  }
  console.log('options', options.value);
  nextTick(() => searchJournals());
});
watch(fromDate, newVal => {
  fromDateText.value = newVal ? formatters.formatDateToMMDDYYYYUTC(newVal) : '';
});
watch(toDate, newVal => {
  toDateText.value = newVal ? formatters.formatDateToMMDDYYYYUTC(newVal) : '';
});
watch(storeId, async () => {
  await searchJournals();
});
onMounted(async () => {
  dispatchApp(store, 'showStoreNumber', true);
});
</script>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
