import { render, staticRenderFns } from "./JournalDetail.vue?vue&type=template&id=65bb9207&scoped=true"
import script from "./JournalDetail.vue?vue&type=script&setup=true&lang=ts"
export * from "./JournalDetail.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./JournalDetail.vue?vue&type=style&index=0&id=65bb9207&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65bb9207",
  null
  
)

export default component.exports