<template>
  <div>
    <v-row align="start" justify="start">
      <v-col>
        <v-text-field
          prepend-icon="search"
          @click:clear="
            setDataSearch = null;
            fetchData();
          "
          v-debounce:300="fetchData"
          v-model="setDataSearch"
          label="Search (UPC, item, desc)"
          :clearable="true"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="store-set-data">
        <v-data-table
          v-if="storeSetData"
          v-model="storeSetData.selected"
          :headers="storeSetData.headers"
          :items="storeSetData.items"
          :single-select="false"
          :loading="storeSetData.loading"
          :items-per-page="50"
          :fixed-header="true"
          :options.sync="storeSetData.options"
          item-key="storeSetDataId"
          @click:row="storeSetData.itemRowMultiClicked($event, storeSetData)"
          @item-selected="storeSetData.itemRowMultiSelected($event, storeSetData)"
          @update:sort-by="storeSetData.get(storeSetDataAdditionalFilter)"
          show-select
          class="elevation-1 scrollable"
          :disable-pagination="true"
        >
          <template v-slot:body.append>
            <infinite-loader
              :pogonaTable="storeSetData"
              :additionalFilter="storeSetDataAdditionalFilter"
              :colspan="storeSetData && storeSetData.headers ? storeSetData.headers.length : 0"
            ></infinite-loader>
          </template>

          <template v-slot:header.data-table-select>
            <v-checkbox
              v-model="storeSetData.allSelected"
              :indeterminate="storeSetData.allSelectedIndeterminate"
              @change="selectStoreSetDataAll"
            ></v-checkbox>
          </template>

          <template v-slot:item.setPrintSequence="{ value }">
            {{ trimZerosFromStart(value) }}
          </template>
        </v-data-table>
        <infinite-paganation :pogonaTable="storeSetData"></infinite-paganation>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <retail-start-date-dialog
          v-model="retailStartDate"
          :queueLoading="setToBatchLoading"
          :queueDisabled="setToBatchDisabled"
          :queueCallback="setToBatch"
          :startDateDialog.sync="startDateDialog"
          :createdBatchInfo="createdBatchInfo"
        ></retail-start-date-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import uuid from 'uuid';
import Vue from 'vue';
import AdalHelpers from '@/utils/AdalHelpers';
import PogonaDataTable from '@/utils/PogonaDataTable';
import StringHelpers from '@/utils/StringHelpers';
import RetailStartDateDialog from './RetailStartDateDialog.vue';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import moment from 'moment';

const StoreSetData = Vue.component('store-set-data', {
  component: {
    RetailStartDateDialog,
  },
  props: {
    value: {
      required: true,
    },
    storeSet: {
      required: true,
    },
    setType: {
      type: Number,
      required: true,
    },
    importId: {
      type: Number,
      required: false,
    },
    storeNumberOverride: {
      type: String,
      required: false,
    },
    allSets: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedTab: {
      type: String,
      required: false,
      default: null,
    },
    selectedStoreSet: {
      required: true,
    },
  },
  data() {
    return {
      storeSetData:
        this.setType === 1
          ? this.$store.state.storeset.storeSetData
          : (this.setType === 2
              ? this.$store.state.newstoreset.storeSetData
              : this.setType === 3
                ? this.$store.state.storesetstage.storeSetData
                : null) || this.value,
      setToBatchLoading: false,
      startDateDialog: false,
      storeSetBatchWs: null,
      adalHelper: new AdalHelpers(),
      retailStartDate: undefined, // undefined so the default in the component will be used
      labelPreviewHeaders: {
        'x-api-host': this.$apiHostAndProtocol,
      },
    };
  },
  computed: {
    ...mapState('app', ['storeNumber']),
    storeSetStore() {
      switch (this.setType) {
        case 1:
          return this.$store.state.storeset;
        case 2:
          return this.$store.state.newstoreset;
        case 3:
          return this.$store.state.storesetstage;
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    storeSetStoreString() {
      switch (this.setType) {
        case 1:
          return 'storeset';
        case 2:
          return 'newstoreset';
        case 3:
          return 'storesetstage';
        default:
          throw new Error(`Set type ${this.setType} not supported`);
      }
    },
    storeSetDataBaseUrl() {
      if (this.selectedTab === 'pendingSets') {
        return `label/pendingstoresetstoreextendeddata/${this.storeNumber}`;
      } else {
        const endCapText = this.selectedTab === 'endCaps' ? 'endcap' : '';
        switch (this.setType) {
          case 1:
            return `label/storeset${endCapText}storeextendeddata/${this.storeNumber}`;
          case 2:
            return `label/newstoresetstoreextendeddata/${this.importId}/${this.storeNumberOverride}`;
          case 3:
            return `label/storeset${endCapText}storeextendeddatastage/${this.storeNumberOverride}`;
          default:
            throw new Error(`Set type ${this.setType} not supported`);
        }
      }
    },
    setDataSearch: {
      get() {
        return this.storeSetStore.setDataSearch || '';
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/setDataSearch', val);
      },
    },
    createdBatchInfo: {
      get() {
        return this.storeSetStore.createdBatchInfo || null;
      },
      set(val) {
        this.$store.commit(this.storeSetStoreString + '/createdBatchInfo', val);
      },
    },
    setToBatchDisabled() {
      const ssb = this.storeSetToBatchData();

      if (!ssb) {
        return true;
      }

      const storeSetItems =
        ssb && ssb.pendingStoreSetIds !== undefined ? ssb.pendingStoreSetIds : ssb.storeSetItems;

      // Disabled when:
      // storeSetToBatchData is null
      // print is in a loading state
      // zero selections are selected
      // batch is set to inclusive (only items that are selected), and zero items are selected
      // ignore the last check, it only makes checking boxes reactive
      const diabled =
        this.setToBatchLoading ||
        (ssb.storeSetItemsInclusive === true && storeSetItems.length === 0) ||
        !(ssb.selected || !ssb.selected); // important to make clicking selected boxes reactive

      return diabled;
    },
    storeSetDataAdditionalFilter() {
      let filter = f('and');
      if (this.selectedTab === 'pendingSets') {
        const pendingstoreSetsInclusive =
          this.selectedStoreSet &&
          this.selectedStoreSet.allSelected === false &&
          this.selectedStoreSet.defaultAllSelected === false;

        const pendingStoreSetDatesActive =
          this.selectedStoreSet && this.selectedStoreSet.trackedDataKeys
            ? this.selectedStoreSet.trackedDataKeys
                .filter(
                  k => this.selectedStoreSet.trackedData[k]._selected === pendingstoreSetsInclusive,
                )
                .map(k => new Date(this.selectedStoreSet.trackedData[k].item.dateActive).getTime())
            : [];

        let minDateActive = null;
        for (const dateActive of pendingStoreSetDatesActive) {
          if (minDateActive === null || dateActive < minDateActive) {
            minDateActive = dateActive;
          }
        }

        if (minDateActive) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.retailStartDate = moment(new Date(minDateActive))
            .startOf('day')
            .format('YYYY-MM-DD');
        }

        const selectedSets = this.selectedStoreSet._selected.map(x => x.pendingStoreSetID);
        filter = f('and').in('pendingStoreSetID', selectedSets);
      } else if (
        this.allSets === false &&
        this.selectedStoreSet.allSelectedIndeterminate === false
      ) {
        const selectedSets = this.selectedStoreSet._selected.map(x => x.setId);
        filter = f('and').in('setId', selectedSets);
      }

      if (this.setDataSearch && this.setDataSearch.length > 0) {
        const trimmedSearch = this.setDataSearch.trim();
        return filter.and(
          f('or')
            .contains("'upc'", trimmedSearch)
            .contains("'itemId'", trimmedSearch)
            .contains("'fulldescription1'", trimmedSearch)
            .contains("'fulldescription2'", trimmedSearch),
        );
      }

      return filter;
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    setupEvents() {
      if (this.storeSetData && this.storeSetData.removeListener) {
        const error = err => {
          this.$emit('snackbar-error', {
            text: 'Error getting store set items',
            err,
            id: '336f8f77-38a6-4c43-b5ee-6a52e2362574',
          });
        };
        const dataBound = () => {
          this.$emit('dataInit');
        };

        this.storeSetData.setMaxListeners(2);
        this.storeSetData.removeListener('error', error);
        this.storeSetData.removeListener('dataBound', dataBound);
        this.storeSetData.on('error', error.bind(this));
        this.storeSetData.on('dataBound', dataBound.bind(this));
      }
    },
    async fetchData() {
      if (this.storeSetData && this.storeSetData.get) {
        await this.storeSetData.get(this.storeSetDataAdditionalFilter);
      }
    },
    storeSetToBatchData() {
      if (!this.selectedStoreSet.selected || this.selectedStoreSet.selected.length === 0) {
        return null;
      }
      // When storeSetItemsInclusive is true, that means only the items
      // that have been selected in the grid will be included in the batch
      // Otherwise, all items will be considered selected,
      // and only ones that have been deselected will be sent.
      const storeSetItemsInclusive =
        this.storeSetData &&
        this.storeSetData.allSelected === false &&
        this.storeSetData.defaultAllSelected === false;

      const storeSetItems =
        this.storeSetData && this.storeSetData.trackedDataKeys
          ? this.storeSetData.trackedDataKeys
              .filter(k => this.storeSetData.trackedData[k]._selected === storeSetItemsInclusive)
              .map(k => this.storeSetData.trackedData[k].item.storeSetDataId)
          : [];

      if (this.selectedTab === 'pendingSets') {
        const pendingStoreSetIds =
          this.storeSetData && this.storeSetData.trackedDataKeys
            ? this.storeSetData.trackedDataKeys
                .filter(k => this.storeSetData.trackedData[k]._selected === storeSetItemsInclusive)
                .map(k => this.storeSetData.trackedData[k].item.pendingStoreSetID)
            : [];

        const storeSetToBatchData = {
          id: uuid(),
          storeNumber: this.storeNumberOverride || this.storeNumber,
          pendingStoreSetIds,
          storeSetItems,
          storeSetItemsInclusive,
          retailStartDate: this.retailStartDate,
          selected: this.storeSetData ? this.storeSetData.selected : [], // important to make clicking selected boxes reactive
        };

        if (this.allSets) {
          storeSetToBatchData.pendingStoreSetIds = null;
        } else {
          storeSetToBatchData.pendingStoreSetIds = this.selectedStoreSet.selected.map(
            x => x.pendingStoreSetID,
          );
        }

        return storeSetToBatchData;
      } else {
        const storeSetToBatchData = {
          id: uuid(),
          storeNumber: this.storeNumber,
          storeSetItems,
          storeSetItemsInclusive,
          retailStartDate: this.retailStartDate,
          selected: this.storeSetData ? this.storeSetData.selected : [], // important to make clicking selected boxes reactive
          areEndCaps: this.selectedTab === 'endCaps',
        };

        if (this.allSets) {
          storeSetToBatchData.setIds = null;
        } else {
          storeSetToBatchData.setIds = this.selectedStoreSet.selected.map(x => x.setId);
        }

        // add new store set properties
        if (this.setType === 2 || this.setType === 3) {
          storeSetToBatchData.storeNumber = this.storeNumberOverride;
          storeSetToBatchData.storeSetDataImportId = this.importId;
        }

        return storeSetToBatchData;
      }
    },
    selectStoreSetDataAll(event) {
      if (event) {
        this.storeSetData.selected = this.storeSetData.items;
      } else {
        this.storeSetData.selected = [];
      }
    },
    async setToBatch(source) {
      const errorMessage = err => {
        this.setToBatchLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating batch from store set.',
          err,
          id: '566a31bf-45a6-4dea-a00d-ebfe33dfbe69',
        });
      };

      try {
        this.startDateDialog = false;
        this.setToBatchLoading = true;

        const adalToken = await this.adalHelper.getJwtToken(this.$authApi);
        let batchCreated = false;

        const onClose = () => {
          // If the web socket connection closed before
          // the batch was created throw an error message.
          if (batchCreated === false) {
            errorMessage();
          }
        };

        // Register on message handler before posting the message
        const onmessage = event => {
          const recMessage = JSON.parse(event.data);

          batchCreated = true;

          this.storeSetBatchWs.close();

          if (recMessage.success === false) {
            errorMessage(recMessage);
            return;
          }

          this.setToBatchLoading = false;
          this.$emit('createdBatchInfoEvent', recMessage.batchInfo);

          this.createdBatchInfo = recMessage.batchInfo;
          this.createdBatchInfo.printImmediately = (source && source.printImmediately) || false;
        };

        let newBatchType = '';
        let serviceBusUrl = '';
        if (this.selectedTab !== 'pendingSets') {
          switch (this.setType) {
            case 1:
              newBatchType = 'store_set_batch';
              serviceBusUrl = 'servicebus/storesetbatch';
              break;
            case 2:
              newBatchType = 'new_store_set_batch';
              serviceBusUrl = 'servicebus/newstoresetbatch';
              break;
            case 3:
              newBatchType = 'store_set_stage_batch';
              serviceBusUrl = 'servicebus/storesetstagebatch';
              break;
            default:
              throw new Error(`SetType ${this.setType} not found.`);
          }
        } else {
          newBatchType = 'pending_store_set_batch';
          serviceBusUrl = 'servicebus/pendingstoresetbatch';
        }

        const storeSetToBatchData = this.storeSetToBatchData();

        const onOpen = async () => {
          await this.$authApi.http.post(serviceBusUrl, storeSetToBatchData);
        };

        this.storeSetBatchWs = new PogonaWebSocket(
          this.$apiBasePath,
          newBatchType,
          storeSetToBatchData.id,
          adalToken,
          onClose,
          onmessage,
          120000,
          onOpen,
        );
      } catch (err) {
        this.$error(err);
        errorMessage(err);
      }
    },
    async getData() {
      this.storeSetData = new PogonaDataTable({
        headers: [
          {
            text: 'UPC #',
            value: 'upc',
          },
          {
            text: 'Item #',
            value: 'itemId',
          },
          {
            text: 'Description 1',
            value: 'fulldescription1',
          },
          {
            text: 'Description 2',
            value: 'fulldescription2',
          },
          {
            text: 'Size',
            value: 'unitsize',
          },
          {
            text: 'UOM',
            value: 'unitmeasure',
          },
          {
            text: 'P/Seq',
            value: 'setPrintSequence',
          },
          {
            text: 'Qty',
            value: 'presentationQty',
          },
          {
            text: 'POG ID',
            value: 'setId',
          },
          {
            text: 'Section',
            value: 'setSectionId',
          },
          {
            text: 'Print Type',
            value: 'svgTemplateDescription',
          },
        ],
        baseUrl: this.storeSetDataBaseUrl,
        httpClient: this.$authApi.http,
        options: {
          itemsPerPage: 50,
          sortBy: ['printSequence', 'setId', 'setPrintSequence'],
        },
        onPageFilter: () => this.storeSetDataAdditionalFilter,
        multiselect: true,
        allSelected: true,
        keys: ['storeSetDataId'],
        isInfinite: true,
      });

      this.setupEvents();
      await this.storeSetData.get(this.storeSetDataAdditionalFilter);
    },
    trimZerosFromStart(value) {
      let finalValue = '';
      if (value) {
        let endOfZeros = false;
        for (const v of value) {
          if (v !== '0') {
            endOfZeros = true;
          }
          if (endOfZeros === true) {
            finalValue += v;
          }
        }
      }
      return finalValue;
    },
  },
  watch: {
    storeNumber: {
      handler: function () {
        this.getData();
      },
    },
    storeSetData: {
      handler: function (val) {
        this.$emit('input', this.storeSetData);
        this.$store.commit(this.storeSetStoreString + '/storeSetData', val);
        this.setupEvents();
      },
      deep: true,
    },
    'selectedStoreSet.selected': {
      handler: async function () {
        await this.getData(this.storeSetDataAdditionalFilter);
      },
      deep: true,
    },
  },
});
export default StoreSetData;
export { StoreSetData };
</script>

<style lang="scss">
.scrollable {
  max-height: 45em !important;
}

.store-set-data .scrollable .v-data-table__wrapper {
  max-height: inherit !important;
}
</style>
