import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useFormatters() {
  // Format a number as currency
  const formatCurrency = (value: any) => {
    return parseFloat(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  // Format a date string as mm/dd/yyyy
  const formatDate = (date: string | Date) => {
    let parsedDate: Date;
    if (typeof date === 'string') {
      parsedDate = new Date(date);
    } else {
      parsedDate = date;
    }
    if (parsedDate.getFullYear() === 1900) {
      return '';
    }
    return parsedDate.toLocaleDateString();
  };

  const formatDatetime = (date, isUtc) => {
    if (date) {
      let time: moment.Moment | null = null;
      if (isUtc) {
        time = moment.utc(String(date)).local();
      } else {
        time = moment(String(date));
      }
      return time.format('M/D/YYYY hh:mm A');
    }
    return '';
  };

  function formatDateToMMDDYYYYUTC(inputDate: Date) {
    const date = new Date(inputDate); // Make sure it's a Date object
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${month}/${day}/${year}`;
  }

  function formatNumberThousands(val: number) {
    return val.toLocaleString();
  }

  function formatBooleanYesNo(val: boolean) {
    return val === true ? 'Yes' : 'No';
  }

  return {
    formatCurrency,
    formatDate,
    formatDatetime,
    formatDateToMMDDYYYYUTC,
    formatNumberThousands,
    formatBooleanYesNo,
  };
}
